<template>
  <overlay-scrollbars class="page page-payment_module" :class="{ 'soon': comingSoon }">
    <div class="row row_top">
      <div class="box box_paymentModule">
        <div class="col col_img"><img src="/img/pm.png"></div>
        <div class="col col_content">
          <div class="ttl">PAYMENT MODULE</div>
          <p class="p3">
            {{ $store.getters.contentArr.payment_module_text_1 }}
          </p>
          <p class="p3">
            {{ $store.getters.contentArr.payment_module_text_2 }}
          </p>
          <div class="footer">
            <div class="btn">{{ $store.getters.contentArr.coming_soon_button }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modules">
      <BannerModule :options="banner.counterParty" />
      <BannerModule :options="banner.transactionRating" />
      <BannerModule :options="banner.bankCompliance" />
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from '@/components/elem/Table.vue'
import BannerModule from '@/components/elem/BannerModule.vue'

import Arrow from '@/components/img/Arrow.vue'
import Dots from '@/components/img/Dots.vue'
import Bank2 from '@/components/img/Bank2.vue'

export default {
  components: {
    Table,
    BannerModule,
    Arrow,
    Dots,
    Bank2,
  },
  data: () => ({
    comingSoon: true,
    banner: {
      counterParty: {
        ttl: '',
        desc: ''
      },
      transactionRating: {
        ttl: '',
        desc: ''
      },
      bankCompliance: {
        ttl: '',
        desc: ''
      },
    }
  }),
  created () {
    if (Object.keys(this.$store.getters.contentArr).length !== 0) {
      this.init()
    }
  },
  methods: {
    init () {
      this.banner.counterParty.ttl = this.$store.getters.contentArr.payment_module_counter_party_label
      this.banner.counterParty.desc = this.$store.getters.contentArr.payment_module_counter_party_text
      this.banner.transactionRating.ttl = this.$store.getters.contentArr.payment_module_transaction_rating_label
      this.banner.transactionRating.desc = this.$store.getters.contentArr.payment_module_transaction_rating_text
      this.banner.bankCompliance.ttl = this.$store.getters.contentArr.payment_module_bank_compliance_label
      this.banner.bankCompliance.desc = this.$store.getters.contentArr.payment_module_bank_compliance_text
    }
  },
  watch: {
    '$store.state.content': function() {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-payment_module {
  &.soon {
    .modules {
      ::v-deep .banner_module {
        filter: blur(5px);

        .btn {
          cursor: default;
        }
      }
    }
  }

  .row {
    &_top {
      display: flex;
      height: 16rem;
      margin-top: 1.5rem;
    }
  }

  .box {
    &_paymentModule {
      width: 100%;
      height: 100%;
      margin: 1rem 0;
      background-color: #ffffff;
      display: flex;

      .col {
        &_img {
          width: 40%;
          display: flex;
          justify-content: center;

          img {
            height: 135%;
            position: relative;
            top: 100%;
            transform: translateY(-92%);
          }
        }

        &_content {
          width: 60%;
          padding: 1rem;
          display: flex;
          flex-direction: column;

          .ttl {
            color: #62CDAC;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            position: relative;
            padding-left: 3rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 2.25rem;
              height: 1px;
              background: #62CDAC;
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 14px;
            }

          }

          p {
            color: #22252B;

            &.p3 {
              padding: .25rem .75rem .25rem 0;
            }
          }

          .footer {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-grow: 1;

            .btn {
              font-size: 18px;
              padding: 1rem 3rem;
              margin: .25rem 1rem;
              background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .modules {
    margin: 3.5rem 0 1rem;
  }
}
</style>